import * as React from 'react';
import { relativeURL } from '../Utils';

interface IServicesSectionProps {}

const ServicesSection: React.FunctionComponent<IServicesSectionProps> = (
  props
) => {
  return (
    <section className="services section-padding" id="services">
      <div className="container">
        <div className="row">
          <div className="col-lg-10 col-12 text-center mx-auto mb-5">
            <small className="small-title">Erbjudande</small>
            <h2>Hur kan vi hjälpa dig?</h2>
          </div>
          <div className="col-lg-6 col-12">
            <nav>
              <div
                className="nav nav-tabs flex-column align-items-baseline"
                id="nav-tab"
                role="tablist"
              >
                <button
                  className="nav-link active"
                  id="nav-business-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-business"
                  type="button"
                  role="tab"
                  aria-controls="nav-business"
                  aria-selected="true"
                >
                  <h3>Frontend</h3>
                  <span>
                    Mobil, Desktop, iOS, Android, Mac, Windows. Native eller
                    x-platform.{' '}
                  </span>
                </button>
                <button
                  className="nav-link"
                  id="nav-strategy-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-strategy"
                  type="button"
                  role="tab"
                  aria-controls="nav-strategy"
                  aria-selected="false"
                >
                  <h3>Backend</h3>
                  <span>
                    Vi har lång erfarenhet av sk fullstack utveckling, och
                    bygger hela din lösning. Vi arbetar gärna med
                    NodeJS/Typescript. Vi sköter om driftsättning och hosting
                    och föredrar serverless infrastruktur. Vi har arbetat mycket
                    med AWS
                  </span>
                </button>
                <button
                  className="nav-link"
                  id="nav-ml-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-ml"
                  type="button"
                  role="tab"
                  aria-controls="nav-ml"
                  aria-selected="false"
                >
                <h3>Maskininlärning</h3>
                  <span>
                    Träna modeller, distribuera och integrera med din lösning. 
                  </span>
                </button>
                <button
                  className="nav-link"
                  id="nav-mt-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-mt"
                  type="button"
                  role="tab"
                  aria-controls="nav-mt"
                  aria-selected="false"
                >
                <h3>Miljöteknik</h3>
                  <span>
                    Fjärranalys, maskinginlärning och multispektral bildanalys
                    från drönare och satelliter.
                  </span>
                </button>
                <button
                  className="nav-link"
                  id="nav-pl-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-pl"
                  type="button"
                  role="tab"
                  aria-controls="nav-pl"
                  aria-selected="false"
                >
                  <h3>Problemlösning</h3>
                  <span>
                    Vi älskar att lösa problem ❤️ Vi går gärna utanför boxen för
                    att bygga det som krävs för din idé.
                  </span>
                </button>
              </div>
            </nav>
          </div>
          <div className="col-lg-6 col-12">
            <div className="tab-content" id="nav-tabContent">
              <div
                className="tab-pane fade show active"
                id="nav-business"
                role="tabpanel"
                aria-labelledby="nav-intro-tab"
              >
                <img
                  src={relativeURL(
                    '/images/services/young-entrepreneurs-mature-investor-watching-presentation-discussing-project.jpg'
                  )}
                  className="img-fluid"
                  alt=""
                />
                <h5 className="mt-4 mb-2">Applikationsutveckling</h5>
                <p>
                  Vi bygger för mobil och desktop. Vi har lång erfarenhet av
                  native mobilutveckling för Apple och Android och har arbetat
                  med appar som Telia Play, C More och TV4 Play. Skype för iOS
                  och Hemnet appen - med flera. Vårt föredragna X-Platform UI
                  ramverk är Flutter.
                </p>
                <ul>
                  <li>
                    Gedigen native mobilutvecklingserfarenhet, iOS och Android
                  </li>
                  <li>Gedigen X-Platform utvecklingserfarenhet i Flutter</li>
                  <li>Native desktop utveckling för Mac och Windows</li>
                </ul>
              </div>
              <div
                className="tab-pane fade show"
                id="nav-strategy"
                role="tabpanel"
                aria-labelledby="nav-strategy-tab"
              >
                <img
                  src={relativeURL(
                    '/images/services/startup-leader-drawing-flowchart-board-discussing-project.jpg'
                  )}
                  className="img-fluid"
                  alt=""
                />
                <h5 className="mt-4 mb-2">Backend</h5>
                <div className="row">
                  <div className="col-lg-6 col-12">
                    <p>
                      Om vi får välja själva, så bygger vi ditt backend API med
                      NodeJS och Typescript. Vi ser till att det blir lätt att
                      utveckla vidare och bygger ofta samtidigt infrastruktur,
                      så att din lösning enkelt kan driftsättas till AWS eller
                      någon annan cloud provider.
                    </p>
                  </div>
                  <div className="col-lg-6 col-12">
                    <p>
                      Vi har erfarenhet av att bygga REST och GraphQL api:er.
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade show"
                id="nav-pl"
                role="tabpanel"
                aria-labelledby="nav-pl-tab"
              >
                <img
                  src={relativeURL('/images/services/climbing.jpg')}
                  className="img-fluid"
                  alt=""
                />
                <h5 className="mt-4 mb-2">
                  Att lösa problem är att andas för oss
                </h5>
                <p>
                  Vi har erfarenhet att integrera direkt med hårdvara såsom
                  betalterminaler, bluetooth utrustning eller andra
                  specialtillämpningar. Vi ryggar inte för någon utmaning och är
                  envisa som åsnor.
                </p>
              </div>

              <div
                className="tab-pane fade show"
                id="nav-ml"
                role="tabpanel"
                aria-labelledby="nav-ml-tab"
              >
                <img
                  src={relativeURL('/images/services/machinelearning.jpg')}
                  className="img-fluid"
                  alt=""
                />
                <h5 className="mt-4 mb-2">Maskininlärning</h5>
                <p>
                Vår spetskompetens inom maskininlärning är särskilt inriktad på miljöteknik, geospatiala problem 
                och analys av multispektrala bilder. Vi har dock också kapacitet att stödja 
                er med andra typer av utmaningar inom området. Dessutom erbjuder vi 
                lösningar för distribution och hosting av era modeller i molnet 
                för att säkerställa att de blir tillgängliga över nätet på ett effektivt och skalbart sätt.
                </p>
              </div>
              <div
                className="tab-pane fade show"
                id="nav-mt"
                role="tabpanel"
                aria-labelledby="nav-mt-tab"
              >
                <img
                  src={relativeURL('/images/services/miljoteknik.png')}
                  className="img-fluid"
                  alt=""
                />
                <h5 className="mt-4 mb-2">Miljöteknik</h5>
                <p>
                  Vi deltar i flera olika forskningsprojekt inom miljöteknik.
                  Vår expertis inom detta område är fjärranalys och analys av
                  multispekttrala bilder. Vi har erfarenhet av att bygga
                  modeller med hjälp av maskininlärning och att använda dessa
                  modeller för att förutspå olika parametrar. Bilderna kan komma
                  från drönare, satelliter eller närmare tagna bilder.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;
